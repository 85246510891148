import { Link, useLocation } from "react-router-dom";

const Navbar = () => {
  const location = useLocation();
  const isHomePage = location.pathname === "/";

  return (
    <nav className="absolute top-0 left-0 w-full z-20 overflow-x-hidden">
      <div className="flex flex-col justify-center items-center w-full">
        <div className="flex justify-between items-center w-full h-full text-sm sm:text-xl py-2 sm:py-4 px-4">
          <div className="flex justify-center items-center w-full">
            <Link
              to="/"
              className="text-gray-800 no-underline text-center px-2 sm:px-5 py-1 sm:py-2 font-bold hover:text-gray-700"
            >
              Home
            </Link>
            <Link
              to="/about"
              className="text-gray-800 no-underline text-center px-2 sm:px-5 py-1 sm:py-2 font-bold hover:text-gray-700"
            >
              About
            </Link>
            <Link
              to="/pricing"
              className="text-gray-800 no-underline text-center px-2 sm:px-5 py-1 sm:py-2 font-bold hover:text-gray-700"
            >
              Pricing
            </Link>
            <Link
              to="/gallery"
              className="text-gray-800 no-underline text-center px-2 sm:px-5 py-1 sm:py-2 font-bold hover:text-gray-700"
            >
              Gallery
            </Link>
          </div>
        </div>
        {!isHomePage && (
          <Link to="/">
            <div className="flex-auto justify-center items-center text-center text-gray-800 font-bold text-lg sm:text-xl">
              Macaulay Takes Pictures
            </div>
          </Link>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
