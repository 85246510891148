import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/footer';
import Loading from './components/Loading';

// Lazy load pages
const Home = lazy(() => import('./pages/Home'));
const About = lazy(() => import('./pages/About'));
const Pricing = lazy(() => import('./pages/Pricing'));
const Gallery = lazy(() => import('./pages/Gallery'));
const PhotoSessionPage = lazy(() => import('./pages/PhotoSessionPage'));
const SportsSessionPage = lazy(() => import('./pages/SportsSessionPage'));
const NotFound = lazy(() => import('./pages/NotFound'));

// Lazy load galleries
const TennisGallery = lazy(() => import('./pages/gallery/Tennis'));
const BaseballGallery = lazy(() => import('./pages/gallery/Baseball'));
const SnowboardingGallery = lazy(() => import('./pages/gallery/Snowboarding'));
const MiscGallery = lazy(() => import('./pages/gallery/Misc'));

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <div className="content">
          <Suspense fallback={<Loading />}>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/about" component={About} />
              <Route path="/pricing" component={Pricing} />
              <Route exact path="/gallery" component={Gallery} />
              
              {/* Gallery category routes */}
              <Route path="/gallery/tennis" component={TennisGallery} />
              <Route path="/gallery/baseball" component={BaseballGallery} />
              <Route path="/gallery/snowboarding" component={SnowboardingGallery} />
              <Route path="/gallery/misc" component={MiscGallery} />
              
              <Route path="/photoSessionPage" component={PhotoSessionPage} />
              <Route path="/sportsSessionPage" component={SportsSessionPage} />
              <Route path="*" component={NotFound} />
            </Switch>
          </Suspense>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;