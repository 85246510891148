import React from 'react';

const Loading = () => {
  return (
    <div className="fixed inset-0 flex flex-col justify-center items-center bg-white z-50">
      <div className="border-8 border-gray-200 border-t-gray-800 rounded-full w-12 h-12 md:w-16 md:h-16 animate-spin"></div>
      <div className="mt-2 md:mt-4 text-lg md:text-2xl text-gray-900">Loading...</div>
    </div>
  );
};

export default Loading;
